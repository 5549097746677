const createScrollElement = () => {
  const scrollbarElement = document.createElement('div');
  scrollbarElement.style.width = '100px';
  scrollbarElement.style.height = '100px';
  scrollbarElement.style.overflow = 'scroll';
  scrollbarElement.style.position = 'absolute';
  scrollbarElement.style.top = '-100%';
  scrollbarElement.style.left = '-100%';
  return scrollbarElement;
};

const getScrollWidth = () => {
  if (typeof document === 'undefined') {
    return 0;
  }
  const element = createScrollElement();
  document.body.appendChild(element);

  const scrollbarWidth = element.offsetWidth - element.clientWidth;
  document.body.removeChild(element);
  return scrollbarWidth;
};

export default getScrollWidth;
